import * as React from 'react'
import { useSearchParams } from 'react-router-dom'
import { ScheduleWeekRootDTORead } from '../../models/ActivityDTO';
import { Context } from '../../context/appContext';
import { ScheduleRequest } from '../../context/service.schedule';
import { endpoints } from '../../context/endpoints';
import './print.scss';
import bg from '../../assets/print_bg.png';
import { DateTimeHelpers } from '../../logic/datetimeHelpers';

export function PrintWeek() {
    let [searchParams, setSearchParams] = useSearchParams();
    const { requestToken } = React.useContext(Context);
    let [data, setData] = React.useState<ScheduleWeekRootDTORead | null>(null);


    React.useEffect(() => {
        fetchdata();
    }, [])

    const trim = (str: string) => {
        return str.replace(/\|.+$/g, '');
    }

    const fetchdata = async () => {
        // get the params from the url: date, days, lang
        let date = searchParams.get('date');
        let days = searchParams.get('days');
        let lang = searchParams.get('lang');

        console.log(date, days, lang);

        if (date == null || days == null || lang == null) {
            alert('no query params');
            return;
        }

        let token = (await requestToken()).accessToken;
        let api = new ScheduleRequest(endpoints.schedule, token);

        let data = await api.getWeekData(date, days, lang.split(','));
        setData(data);
    }

    if (data == null) {
        return <div>Loading...</div>
    }

    // refactor data.days to group by event per day


    return (
        <div className='printWeek'>
            <div className='printWeek__header' style={{
                backgroundImage: `url(${bg})`
            }}>
                <div className='printWeek__header__title'>
                    {data.title.map((title, index) => <div className={`printWeek__header__title__title--${index}`} key={index}>{title}</div>)}
                </div>
                <div className='printWeek__header__date'>
                    {DateTimeHelpers.GetDateString(data.startDate)} - {DateTimeHelpers.GetDateString(data.endDate)}
                </div>
            </div>

            {data.days.map((day, index) => {
                // group by event
                // sort by start time
                interface IGroupTimes {
                    start: string,
                    end: string,
                    duration: string
                }
                interface IGroupedActivities {
                    aTitle: string[]
                    aTimes: IGroupTimes[],
                }

                let groupedActivities: IGroupedActivities[] = [];
                day.activities.forEach(activity => {
                    let index = groupedActivities.findIndex(x => x.aTitle.join('') == activity.title.join(''));
                    if (index == -1) {
                        groupedActivities.push({
                            aTitle: activity.title,
                            aTimes: [{
                                start: activity.start,
                                end: activity.end,
                                duration: '',
                            }]
                        })
                    } else {
                        groupedActivities[index].aTimes.push({
                            start: activity.start,
                            end: activity.end,
                            duration: ''
                        })
                    }
                })


                return (
                    <div className='printWeek__day' key={index}>
                        <div className='printWeek__day__wrapper'>
                            <div className='printWeek__day__header'>

                                <div className='printWeek__day__header__day'>
                                    {day.weekday.map((day, indexWeekday) => <div className={`printWeek__day__header__day__day--${indexWeekday}`} key={indexWeekday}>{day}</div>)}
                                </div>
                                <div className='printWeek__day__header__date'>
                                    {DateTimeHelpers.GetDateString(day.date)}
                                </div>

                            </div>
                            <div className='printWeek__day__activities'>
                                {groupedActivities.map((activity, index) => {

                                    return (
                                        <div className='printWeek__day__activities__activity' key={index}>
                                            <div className='printWeek__day__activities__activity__name'>

                                            {activity.aTitle.map(x => trim(x)).join(' | ')}

                                                {/* {activity.aTitle.map((title, indexActivity) => <div className={`printWeek__day__activities__activity__name__title--${indexActivity}`} key={indexActivity}>{trim(title)}</div>)} */}
                                            </div>
                                            <div className='printWeek__day__activities__activity__time'>
                                            {activity.aTimes.map((time, indexTime) => {
                                                return (
                                                    <span key={indexTime}>
                                                        {time.start}
                                                         {/* - {time.end} */}
                                                    </span>
                                                )
                                            })}
                                            </div>
                                        </div>
                                    )

                                })}


                                {/* {day.activities.map((activity, index) => {
                                    return (
                                        <div className='printWeek__day__activities__activity' key={index}>
                                            <div className='printWeek__day__activities__activity__time'>
                                                {activity.start} - {activity.end}
                                            </div>
                                            <div className='printWeek__day__activities__activity__name'>
                                                {activity.title.map((title, indexActivity) => <div className={`printWeek__day__activities__activity__name__title--${indexActivity}`} key={indexActivity}>{title}</div>)}
                                            </div>
                                        </div>
                                    )
                                })} */}
                            </div>
                        </div>
                    </div>
                )
            })}
        </div>
    )

}